<template>
  <div class="exam">
    <div v-if="!isProcessing">
      <div class="exam__top">
        <p class="exam__top__occupation">{{ occupation.name }}</p>
        <v-progress-circular
          :rotate="-90"
          :size="circularSize"
          :width="26"
          :value="progress"
          color="#ff9d44">
          <p class="exam__top__progress">
          {{ progress }}%
          </p>
        </v-progress-circular>
        <div class="exam__top__achieve">
          <div class="exam__top__achieve__box--times">
            <v-icon class="exam__top__achieve__box__icon">mdi-timer</v-icon>
            <p class="exam__top__achieve__box__text">Số lần</p>
          </div>
          <div class="exam__top__achieve__box--full">
            <img class="exam__top__achieve__box__img" src="@/assets/img/trophy.svg" />
            <p class="exam__top__achieve__box__text">Điểm tuyệt đối</p>
        </div>
        </div>
      </div>
      <div class="exam__score">
        <div class="exam__score__box--times">
          <p class="exam__score__box__text">{{ times }}<span class="exam__score__box__text__small">Lần</span></p>
        </div>
        <div class="exam__score__box--full">
          <p class="exam__score__box__text">{{ full }}<span class="exam__score__box__text__small">Lần</span></p>
        </div>
      </div>
      <h1 class="exam__title">Các bài thi</h1>
      <div v-for="eid in Object.keys(exams)" :key=eid>
        <exam-detail class="exam__detail" v-if="isPublishedExams[eid]" :exam=exams[eid] :eid=eid />
      </div>
    </div>
    <!-- 処理中 -->
    <processing v-if="isProcessing" />
  </div>
</template>

<script>
import ExamDetail from '@/components/exam/detail'
import Processing from '@/components/common/processing'

export default {
  name: 'exam',
  components: { ExamDetail, Processing },
  data () {
    return {
      // 処理中かどうか
      isProcessing: true
    }
  },
  /**
   * 初回アクセス時の取得処理
   * データがstoreに格納されていなければ下記のフローで取得する
   * (1) 職業情報(occupation)と職業ID(oid)に紐づく試験情報(exams)を取得
   * (2) 試験ID(eid)に紐づく問題情報(questions)を取得
   * (3) 試験ID(eid)に紐づく試験が公開条件を満たしていたら、結果情報(results)を取得
   */
  async mounted () {
    // 試験数
    const EXAM_AMOUNT = 5

    // (1) 職業情報(occupation)と職業ID(oid)に紐づく試験情報(exams)を取得
    const promises = []
    // 職業情報をチェック
    if (!Object.keys(this.$store.getters['occupation/occupation'](this.user.occupation)).length) promises.push(this.$store.dispatch('occupation/getOccupation', this.user.occupation))
    // 試験情報をチェック
    // ・プロフィールの職業を変えたタイミングでstoreを破棄する仕様で、他職業のデータはstoreに格納されていない想定
    // ・問題画面->結果画面->一覧画面と遷移した場合、試験情報がひとつだけ格納されている状態のため、storeデータ数がEXAM_AMOUNT以外であれば取得
    if (!this.$store.getters['exams/exams']() || (Object.keys(this.$store.getters['exams/exams']()).length !== EXAM_AMOUNT)) promises.push(this.$store.dispatch('exams/getExams', this.user.occupation))
    if (promises.length > 0) await Promise.all(promises)

    // (2) 試験ID(eid)に紐づく問題情報(questions)を取得
    const questionsPromises = []
    const eids = this.$store.getters['exams/exams']() ? Object.keys(this.$store.getters['exams/exams']()).map(eid => eid) : []
    // 試験問題情報をチェック
    eids.filter(eid => {
      // 試験IDごとに問題を持っているかチェック、storeに無ければ取得
      if (!this.$store.getters['questions/questions'](eid)) questionsPromises.push(this.$store.dispatch('questions/getQuestions', eid))
    })
    if (questionsPromises.length > 0) await Promise.all(questionsPromises)

    // (3) 問題ID(qid)に紐づく結果情報(results)を取得
    const resultsPromises = []
    // 試験結果情報をチェック
    eids.filter(eid => {
      // 問題が公開条件を満たしている && 結果情報がstoreに存在しない場合のみ取得
      if (this.isPublishedExams[eid] && !this.$store.getters['results/result'](eid)) {
        resultsPromises.push(this.$store.dispatch('results/getResult', { eid: eid, uid: this.uid }))
      }
    })
    if (resultsPromises.length > 0) await Promise.all(resultsPromises)

    // 初回アクセス時の処理終了
    this.isProcessing = false
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['user/user']
    },
    /**
     * @return {Object} 職業情報
     */
    occupation () {
      return this.$store.getters['occupation/occupation'](this.user.occupation)
    },
    /**
     * @return {Object} 試験情報一覧
     */
    exams () {
      return this.$store.getters['exams/exams']()
    },
    /**
     * @return {Object} 公開条件を満たしている試験情報一覧
     */
    publishExams () {
      return this.exams ? Object.keys(this.exams).reduce((exams, eid) => {
        if (this.isPublishedExams[eid]) exams[eid] = this.exams[eid]
        return exams
      }, {}) : {}
    },
    /**
     * @return {Object} 試験結果情報一覧
     */
    results () {
      return this.$store.getters['results/results']()
    },
    /**
     * @return {Number} 円グラフのサイズ
     */
    circularSize () {
      return window.parent.screen.width * 0.6
    },
    /**
     * @return {Number} テストの達成度
     */
    progress () {
      // ひとつの試験につき3回まで満点をカウントする
      const MAX_COUNT = 3
      const full = this.results ? Object.values(this.results).reduce((count, result) => result.full > MAX_COUNT ? count + MAX_COUNT : count + result.full, 0) : 0
      // 達成度 = 満点カウント / ( 最大カウント数 * 表示されている試験数 )
      return Object.keys(this.publishExams).length ? Math.round(full / (MAX_COUNT * Object.keys(this.publishExams).length) * 100) : 0
    },
    /**
     * @return {Number} テストの受験回数
     */
    times () {
      return this.results ? Object.values(this.results).reduce((count, result) => count + result.times, 0) : 0
    },
    /**
     * @return {Number} 満点の獲得回数
     */
    full () {
      return this.results ? Object.values(this.results).reduce((count, result) => count + result.full, 0) : 0
    },
    /**
     * 公開済みの試験かどうか
     * { eid: true/false, eid: true/false, ... }
     * @return {Object} 各試験が問題を持つかどうかの配列
     */
    isPublishedExams () {
      // 試験問題数
      const QUESTION_AMOUNT = 20
      const isPublishedExams = this.exams ? Object.keys(this.exams).reduce((isPublishedExam, eid) => {
        // 紐づく問題が20問ある場合のみ試験を表示する
        isPublishedExam[eid] = this.$store.getters['questions/questions'](eid) && Object.keys(this.$store.getters['questions/questions'](eid)).length === QUESTION_AMOUNT
        return isPublishedExam
      }, {}) : {}
      return isPublishedExams
    }
  },
  methods: {
    /**
     * 戻る
     */
    back () {
      this.$router.push({ name: 'Study' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.exam {
  min-height: calc( 100vh - #{$header_height} );
  background-color: #f6f6f6;
  text-align: center;
  padding: 0 $main_padding 50px;
  &__top {
    margin: 0 -#{$main_padding};
    padding-top: 50px;
    background-color: white;
    &__progress {
      margin: auto;
      font-size: 40px;
      line-height: 55px;
      color: #393939 !important;
    }
    &__occupation {
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 34px;
    }
    &__achieve {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;
      padding: 0 $main_padding;
      color: white;
      &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 46px;
        background-color: #ff9d44;
        border-radius: 5px 5px 0 0;
        &__icon {
          margin-right: 5px;
          font-size: 20px;
          line-height: 20px;
          &.theme--light.v-icon {
            color: white;
          }
        }
        &__text {
          margin: 0;
          font-size: 15px;
          line-height: 20px;
        }
        &__img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        &--times {
          @extend .exam__top__achieve__box;
          margin-right: 12px;
        }
        &--full {
          @extend .exam__top__achieve__box;
          margin-left: 12px;
        }
      }
    }
  }
  &__score {
    display: flex;
    align-items: center;
    color: white;
    &__box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 46px;
      background-color: #ffa758;
      border-radius: 0 0 5px 5px;
      &__text {
        margin: 0;
        font-size: 30px;
        line-height: 35px;
        &__small {
          font-size: 15px;
          line-height: 20px;
        }
      }
      &--times {
        @extend .exam__score__box;
        margin-right: 12px;
      }
      &--full {
        @extend .exam__score__box;
        margin-left: 12px;
      }
    }
  }
  &__title {
    margin: 30px 0 0;
    text-align: left;
    font-size: 20px;
    line-height: 27px;
  }
  &__detail {
    margin: 10px 0 0;
  }
}
</style>

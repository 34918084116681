<template>
  <div class="detail" @click="$router.push({ name: 'ExamQuestion', params:{eid: eid} })">
    <div class="detail__info">
      <p class="detail__info__name">{{ exam.name }}</p>
      <div class="detail__info__cover">
        <table class="detail__info__cover__table">
          <tr>
            <td class="detail__info__cover__table__title">Tiến độ</td>
            <td class="detail__info__cover__table__item">
              <div class="detail__info__cover__table__item__full">
                <div class="detail__info__cover__table__item__full__circle">
                  <div class="detail__info__cover__table__item__full__circle__small" v-if="full >= 1" />
                </div>
                <div class="detail__info__cover__table__item__full__circle">
                  <div class="detail__info__cover__table__item__full__circle__small" v-if="full >= 2" />
                </div>
                <div class="detail__info__cover__table__item__full__circle">
                  <div class="detail__info__cover__table__item__full__circle__small" v-if="full >= 3" />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="detail__info__cover__table__title">Ngày thi</td>
            <td class="detail__info__cover__table__date">{{ date }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="detail__score">
      <p class="detail__score__text">Số điểm tốt nhất</p>
      <p class="detail__score__number">{{ score }}/100</p>
    </div>
  </div>
</template>

<script>
import Common from '@/lib/common'

export default {
  mixins: [Common],
  props: {
    // 試験ID
    eid: {
      type: String,
      required: true
    },
    // 試験情報
    exam: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * @return {Object} 試験情報IDに紐付いた試験結果情報
     */
    result () {
      return this.$store.getters['results/result'](this.eid)
    },
    /**
     * @return {String} テストの最終実施日
     */
    date () {
      return this.result ? this.formated(this.result.updatedAt, 'YYYY.MM.DD') : '-'
    },
    /**
     * @return {Number} テストの最高点数
     */
    score () {
      return this.result ? this.result.best : '-'
    },
    /**
     * @return {Number} 満点の獲得回数
     */
    full () {
      return this.result ? this.result.full : 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.detail {
  padding: 26px 16px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  &__info {
    flex: 1;
    min-width: 0;
    text-align: left;
    &__name {
      margin: 0;
      font-size: 20px;
      line-height: 27px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__cover {
      display: flex;
      align-items: center;
      flex: 1;
      min-width: 0;
      &__table {
        margin-top: 6px;
        border: 0;
        font-size: 15px;
        line-height: 20px;
        z-index: 1;
        &__date {
          padding: 4px 4px 4px 0;
        }
        &__item {
          padding: 4px;
          &__full {
            display: flex;
            align-items: center;
            &__circle {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 5px;
              height: 20px;
              width: 20px;
              background-color: #fffadf;
              border-radius: 50%;
              &__small {
                height: 12px;
                width: 12px;
                background-color: #ff9d44;
                border-radius: 50%;
              }
            }
          }
        }
        &__date {
          padding: 4px 4px 4px 9px;
        }
      }
    }
  }
  &__score {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
    background-color: #fffadf;
    border-radius: 50%;
    &__text {
      margin: 0;
      font-size: 12px;
      line-height: 17px;
    }
    &__number {
      margin: 0;
      font-size: 20px;
      line-height: 27px;
    }
  }
}
</style>
